import React from 'react'
import Card from '../Card'
import {useHistory} from "react-router-dom"

function WhoWeAre() {
    const history = useHistory()

    const info = [
        {
        id: "01",
        name: "Learnflo UK",
        url: "https://learnflo.co.uk/",
    },
    {
        id: "02",
        name: "Learnflo Africa",
        url: "https://learnflo.org/",
    },
    {
        id: "03",
        name: "LearnFlo HI",
        url: "https://learnflo.school/",
    }
]
    return (
        <div className='who-we-are-component' id='who-we-are'>

            <div className='header-component'>
                <div className='header-div'>
                    <h3 className='header'>_who we are</h3>
                    <h1 className='who-we-are-text'>
                        We're Learnflo Limited.<br /> We offer customized technology solutions that help effectively deliver learning across Africa.
                    </h1>

                    <button onClick={() => history.push('/about')}>READ MORE ABOUT US</button>
                   <div className="card-content">
                   {
                        info && info?.map(data => {
                            return <Card id={data.id} name={data.name} url={data.url} />
                        })
                    }
                   </div>
                </div>
            </div>
        </div>
    )
}

export default WhoWeAre
