import React from 'react'
import {Navbar, Container, Nav } from "react-bootstrap"
import {useHistory} from "react-router-dom"


function Navbars() {
  const history = useHistory()


    return (
      <Navbar bg="light" expand="lg">
      <Container fluid  className='nav-container'>
        <Navbar.Brand href="/"><img src="https://res.cloudinary.com/doouwbecx/image/upload/v1642321847/logo_ivqvos.svg" alt='' /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
          <Nav className="me-auto">
            <p className='nav-item' onClick={() => history.push('/')}>HOME</p>
            <p className='nav-item' onClick={() => history.push('/about')}>ABOUT US</p>
            <p className='nav-item' onClick={() => history.push('/contact')}>CONTACT US</p>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}

export default Navbars