
import React from 'react'
import { Row, Col, Accordion } from "react-bootstrap"

function OurTeam() {


    const teams = [
        {
            id: 1,
            name: 'Mr. Tolu Fagbola',
            role: 'Executive Director',
            image: 'https://res.cloudinary.com/doouwbecx/image/upload/v1650540424/20210209_135903_cuwe4y.jpg',
            info: `Tolu Fagbola is an Agile Transformation and Training expert with over 12 years of experience in Training and Development with a niche in learning technologies. He led the team that implement the LMS System project for Skye bank (now Polaris Bank) over 10 years ago and has managed a number of content development 
            projects for Diamond Bank. Tolu has gone on to manage the development of 
            systems for global organizations including General Dynamics in the UK and 
            A2Dominion Ltd also in the UK. Tolu has overseen the development of multiple LMS 
            development systems including Moodle, Joomla, Cornerstone on Demand and 
            bespoke solutions`
        },
        {
            id: 2,
            name: 'Dr. Busayo Ajayi ',
            role: 'Non-Executive Director',
            image: 'https://res.cloudinary.com/doouwbecx/image/upload/v1648484609/learnflo/WhatsApp_Image_2022-03-28_at_1.28.54_PM_u4f393.jpg'
        },
        {
            id: 3,
            name: 'Mr. Segun Aderinto',
            role: 'Chief Technical Officer',
            image: 'https://res.cloudinary.com/doouwbecx/image/upload/v1650540415/DSC_4876_mnalix.jpg'
        },
        {
            id: 4,
            name: 'Mr. Adetola Adegoke',
            role: 'Chief Operating Officer and Business team lead',
            image: 'https://res.cloudinary.com/doouwbecx/image/upload/v1650540409/png_20220420_210528_0000_ruibnf.png'
        },
        {
            id: 5,
            name: 'Olubusola Ogundare',
            role: 'Lead Business Analyst/Scrum Master',
            image: 'https://res.cloudinary.com/doouwbecx/image/upload/v1649021989/WhatsApp_Image_2022-03-30_at_8.42.18_AM_hrcmvj.jpg'
        },
        {
            id: 6,
            name: 'Mr. Edwards Moses',
            role: 'Lead Developer',
            image: 'https://res.cloudinary.com/doouwbecx/image/upload/v1648484611/learnflo/WhatsApp_Image_2022-03-22_at_2.14.21_PM_cu2zwd.jpg'
        },

    ]
    return (
        <div className='our-team-component' id="team">

            <div className='header-component'>
                <div className='header-div'>
                    <h3 className='header'>_OUR TEAM</h3>
                    <Row>
                        {
                            teams?.map(data => {
                                return <Col xl="4" lg="4" md="6" sm="6" xs="12">
                                    <div className='team-container'>
                                        <img src={data?.image} className="image" alt="" />
                                        <h4 className="team-name">{data?.name}</h4>
                                        <p className="team-role">{data?.role}</p>
                                        {/* <Accordion alwaysOpen>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>View Detail</Accordion.Header>
                                                <Accordion.Body>
                                                        {data?.info}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion> */}
                                    </div>
                                </Col>
                            })
                        }
                    </Row>

                </div>
            </div>
        </div>
    )
}

export default OurTeam