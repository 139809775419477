import React from 'react'

function Card({id, name, url}) {
    return (
        <div className='card-component'>
            <a href={url} target="_blank" style={{textDecoration: 'none'}}>
                 <p className='card-headers'>{id}</p>
            <p className='card-text'>{name}</p>
            <img src="https://img.icons8.com/material-rounded/24/000000/long-arrow-right.png" alt=""/>
            </a>
           
        </div>
    )
}

export default Card
