import React from 'react'
import Navbars from '../../components/Navbar'
import { Row, Col } from "react-bootstrap"
import Footer from '../../components/Footer'

function AboutUs() {
    return (
        <>
            <Navbars />
            <div className='about-container'>
                <h3>About us</h3>
                {/* <img className='image-about' src='' /> */}
            </div>
            <div className='about-component'>
                <Row>
                    <Col lg="6" md="12" sm="12" xs="12">
                        <p>Learnflo is a Learning and Student Management Solution provider that caters
                            to the need of individual trainers, training institutions, corporate
                            organizations and higher institutions alike.</p>
                        <p>Our e-learning platform is a proprietary solution built from scratch using the
                            Microsoft stack including ASP.Net MVC framework and hosted on Microsoft
                            Azure. This approach indicates stability and robustness of our solution. The
                            approach also indicates the flexibility of integrating into many other
                            enterprise applications.</p>
                        <p>Learnflo can be deployed as a cloud-based Software, Service (SaaS) or a
                            bespoke Learning Management Solution
                        </p>

                    </Col>

                    <Col lg="6" md="12" sm="12" xs="12">
                        <p>Our strength lies in the expert skills of our team who have extensive
                            experience in developing and deploying e-learning systems for different
                            organizations and institutions in Nigeria and in the UK. Our team are also
                            skilled and experienced in leading large scale implementation projects for
                            large international organizations.</p>
                        <p>Our project delivery approach is the key differentiator between our solution
                            and our competitors as we believe software solutions that will only add
                            business value if they are aligned to the organization’s goals and encourage
                            organizations design their courses and trainings along their growth and
                            development paths as it relates to current realities in their environments and
                            the world over.</p>
                    </Col>
                </Row>
            </div>
            {/* <div className='about-component2'>
                <Row>
                    <Col lg="6" md="12" sm="12" xs="12">
                        <div>
                            <img src='https://res.cloudinary.com/doouwbecx/image/upload/v1650586288/visionedited_nys8dx.gif' />
                            <h3>Vision Statement</h3>
                            <p>Making learning and education accessible and affordable to everyone
                            </p>
                        </div>

                    </Col>

                    <Col lg="6" md="12" sm="12" xs="12">
                        <div>
                            <img src='https://res.cloudinary.com/doouwbecx/image/upload/v1650586288/8KgF_vphgxh.gif' />
                            <h3>Mission Statement</h3>
                            <p>We are developing a world-class, effective and affordable customized technology solutions that makes the delivery of learning and education across Africa very accessible using a team of bold, committed and experienced staff
                            </p>
                        </div>

                    </Col>
                </Row>

            </div> */}

            <Footer />
        </>
    )
}

export default AboutUs