import React from 'react'
import {useHistory} from "react-router-dom"

function Footer() {
    const history = useHistory()


    return (
        <div className='footer-component'>
            <div className='header-component'>
                <div className='footer-content'>
                    <div className='footer-div'>
                        <h3 className='footer-header'>_our team</h3>
                        <a href='#who-we-are' className='footer-anchor'>Who we are?</a>
                        <a href='#team' className='footer-anchor'>Our team</a>
                        <p onClick={() => history.push('/about')} style={{cursor: 'pointer'}} className='footer-anchor'>About us</p>
                    </div>
                    <div className='footer-div'>
                        <h3 className='footer-header'>_CONTACT</h3>
                        <p onClick={() => history.push('/contact')} style={{cursor: 'pointer'}} className='footer-anchor'>Contact us</p>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <hr style={{color: 'white'}}/>
            <div className='header-component'>
                <div className='footer-content'>
                    <div className='footer-div'>
                        <a href='' className='footer-anchor'>© 2020 LearnFlo Limited</a>
                    </div>
                    <div className='footer-div'>
                        <a href='' className='footer-anchor'>Privacy policy | Cookies</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
