import React from 'react'
import Navbars from '../../components/Navbar'
import { Container, Row, Col } from "react-bootstrap"
import Footer from '../../components/Footer'

function Contact() {
    return (
        <>
            <Navbars />
            <div className='contact-container'>
                <h3>Contact us</h3>
            </div>
            <Container>
                <Row>
                    <Col lg="4" md="4" sm="12">
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <i class="bi bi-geo-alt-fill" style={{ fontSize: '100px' }}></i>
                            <h3>Address</h3>
                            <p>2a, Oluwakemi Street, Off Akeem Lasisi, Shasha, Akowonjo, Lagos</p>
                        </div>
                    </Col>
                    <Col lg="4" md="4" sm="12">
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <i class="bi bi-phone" style={{ fontSize: '100px' }}></i>
                            <h3>Phone</h3>
                            <p><span><i class="bi bi-telephone" style={{ fontSize: '15px' }}></i></span> +44 7944 674960</p>
                            <p><span><i class="bi bi-telephone" style={{ fontSize: '15px' }}></i></span> +234 803 719 4130</p>
                        </div>
                    </Col>
                    <Col lg="4" md="4" sm="12">
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <i class="bi bi-envelope" style={{ fontSize: '100px' }}></i>
                            <h3>Email</h3>
                            <p>adetola.adegoke@learnflo.co</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <br/>
            <Footer />
        </>
    )
}

export default Contact