import React from 'react'
import { Row, Col } from "react-bootstrap"

function Header() {
    return (
        <div className='header-component'>
            <div className='header-div'>
                <Row>
                    <Col lg="8" md="6" sm="12" xs="12">
                        <h3 className='header'>Learnflo_Learning technology company</h3>
                        <h1 className='header-text'>We believe that the World Of Smart learning in Africa is here</h1>
                    </Col>
                    <Col lg="4" md="6" sm="6" xs="12" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div className='header-container'>
                            <p className='header-paragraph'>"No country can really develop unless its citizens are educated."</p>
                            <p className='header-paragraph2'>-Nelson Mandela</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                     <img style={{width: '100%'}} src="https://res.cloudinary.com/doouwbecx/image/upload/v1642326569/MathiciLimited_Row_3_q77lvy.png" alt=''/>
                    </Col>
                </Row>
                <br/>
                <br/>
            </div>

        </div>

    )
}

export default Header
