import React from 'react'
import Navbars from "../../components/Navbar"
import Header from '../../components/Header'
import WhoWeAre from '../../components/WhoWeAre'
import Footer from '../../components/Footer'
import OurTeam from '../../components/OurTeam'

function Home() {
    return (
        <>
         <Navbars />
         <Header />
         <WhoWeAre />
         <OurTeam />
         <Footer />
        </>
       
    )
}

export default Home
